/* eslint-disable no-unused-vars */
import { createContext } from "react";

const ctx = createContext({
  autocompleteAddress: ({ search, selected }) => Promise({}),
  addCardToCustomer: (customerName, card, stripe) => Promise({}),
  overlay: {},
  setOverlay: (text) => {},
});
export default ctx;
