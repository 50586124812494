import { createSlice } from "@reduxjs/toolkit";

export const CASHIER_ONEOFF = "oneoff";
export const CASHIER_PLAN = "plan";

// http://localhost:3000/?email=corbinhibbs@gmail.com
const initialparams = Object.fromEntries(
  new URLSearchParams(window.location.search).entries(),
);
const { email, deal } = initialparams;

const initialState = {
  selectedEmail: email || "",
  selectedDeal: deal || "",
  stripeCustomerId: "",
  deal: { id: "" },
  stripeCustomer: {},
  contact: {},
  scheduledPayments: [],
  mode: CASHIER_ONEOFF,
};

const cashierReducer = createSlice({
  name: "cashier",
  initialState,
  reducers: {
    selectStripeCustomer: (state, action) => {
      const { payload } = action;
      state.stripeCustomerId = payload;
    },
    selectDeal: (state, action) => {
      const { payload } = action;
      state.selectedDeal = payload;
    },
    selectEmail: (state, action) => {
      const { payload } = action;
      state.selectedEmail = payload;
    },
    setDeal: (state, action) => {
      const { payload } = action;
      state.deal = payload;
    },
    setContact: (state, action) => {
      const { payload } = action;
      state.contact = payload;
    },
    setStripeCustomer: (state, action) => {
      const { payload } = action;
      state.stripeCustomer = payload;
    },
  },
});

export const {
  setStripeCustomer,
  selectEmail,
  setContact,
  selectDeal,
  setDeal,
  selectStripeCustomer,
} = cashierReducer.actions;
export default cashierReducer.reducer;
