// tips https://blog.stunning.co/stripe-decline-codes/
// https://stripe.com/docs/declines/codes

import { addMonths, isAfter } from "date-fns";
import countryList from "react-select-country-list";

export const countries = countryList().getData();

export const dt = (datestr) => new Date(datestr);

export const fullName = (contact) =>
  `${contact?.firstname || ""} ${contact?.lastname || ""}`.trim();

export const localTimeFromUTCtime = (utc_date) => {
  // if utc_date is "2022-01-01T00:00:00Z",
  // build local date representation of that _date, midnight_ in local time zone
  const ymd = utc_date.toISOString().substr(0, 10).split("-");
  return new Date(parseInt(ymd[0]), parseInt(ymd[1]) - 1, parseInt(ymd[2]));
};
export const explainDecline = (err) => {
  // expects
  // {
  //   code: 'card_declined',                // or other message
  //   decline_code: 'insufficient_funds',   // or other message
  //   message: 'message from stripe'
  // }
  const declineCode =
    err.code === "card_declined" ? err.decline_code || err.code : err.code;

  switch (declineCode) {
    // all the following are "unknown reason; customer must contact the bank"
    case "card_declined":
    case "generic_decline":
    case "call_issuer":
    case "do_not_honor":
    case "do_not_try_again":
    case "invalid_account":
    case "new_account_information_available":
    case "no_action_taken":
    case "not_permitted":
    case "restricted_card":
    case "revocation_of_all_authorizations":
    case "revocation_of_authorization":
    case "security_violation":
    case "service_not_allowed":
    case "stop_payment_order":
    case "transaction_not_allowed":
      return `The card was declined and the bank did not specify why. The customer needs to contact the card issuer.`;

    // more helpful messages
    case "insufficient_funds":
      return `The card was declined due to insufficient funds.`;

    case "error_on_requires_action":
    case "authentication_required":
      return `Unsupported card. The card may be valid but requires authentication, and we currently don't support that. Try another card.`;

    case "approve_with_id":
      return `Try again once. If it fails, the customer needs to contact the card issuer.`;

    case "card_not_supported":
      return `Card is not supported. The customer needs to contact the card issuer.`;

    case "withdrawal_count_limit_exceeded":
    case "card_velocity_exceeded":
      return `Card velocity exceeded, probably some daily/weekly/monthly limit. The customer needs to contact the card issuer.`;

    case "currency_not_supported":
      return `Card does not support payment in US dollars. The customer needs to contact the card issuer.`;

    case "duplicate_transaction":
      return `Duplicate transaction. A recent charge of same amount and same card was detected. Please check payment logs.`;

    case "expired_card":
      return `Card has expired. The customer needs to contact the card issuer to renew it.`;
    case "invalid_number":
    case "incorrect_number":
      return `Bad card number. If the card is on file, delete it. If not, double check the number.`;
    case "invalid_cvc":
    case "incorrect_cvc":
      return `Bad card CVC number. If the card is on file, delete it. If not, double check the number.`;

    case "offline_pin_required":
    case "online_or_offline_pin_required":
    case "invalid_pin":
    case "incorrect_pin":
    case "pin_try_exceeded":
      return `Bad card PIN. This should never happen as the user has no way of entering a PIN in this system.`;

    case "incorrect_zip":
      return `Bad ZIP code. This should never happen as we don't check ZIP codes in this system.`;
    case "invalid_amount":
      return `Invalid amount. If the amount looks OK, the customer needs to contact the card issuer about possible limits.`;
    case "invalid_expiry_month":
      return `Bad card expiry month. If the card is on file, delete it. If not, double check the month.`;
    case "invalid_expiry_year":
      return `Bad card expiry year. If the card is on file, delete it. If not, double check the year.`;

    case "processing_error":
    case "issuer_not_available":
    case "reenter_transaction":
    case "try_again_later":
      return `Issuer not available. Retry in a few; the issuer is offline. If it still fails, the customer needs to contact the card issuer`;

    case "lost_card":
    case "pickup_card":
    case "stolen_card":
      return `Card was declined. DON'T tell the customer, but Stripe reports this as a lost/misplaced/stolen card.`;

    case "merchant_blacklist":
      return `Card was declined due to merchant blacklisting. Please report this to the Mastermind IT department.`;

    case "fraudulent":
      return `Card was declined. DON'T tell the customer, but Stripe suspects a Fraudulent transaction.`;

    case "testmode_decline":
      return `The entered card is a Stripe test card and cannot be used for real world purchases. Enter a real card number.`;

    default:
      return err.message || "Declined";
  }
};

export const formatCents = (cents) =>
  `$${(Math.round(+cents || 0) / 100).toFixed(2)}`;

export const formatDollars = (dollars) => formatCents(+dollars * 100);

export const checkCardValidity = (pm, paymentDate) => {
  if (!pm?.card) {
    return true; // link for example, assume ok
  }
  if (!pm) {
    return false;
  }
  const cardValidityDate = new Date(pm.exp_year, parseInt(pm.exp_month) - 1, 1);

  const payDate = new Date(
    paymentDate.getUTCFullYear(),
    paymentDate.getUTCMonth(),
    1,
  );

  return !isAfter(payDate, cardValidityDate);
};

export const figureRecurrance = (entry) => {
  // turns out Hubspot adds "P" and "M" to whatever number you put into the Term field
  // so Term = 6 yields the value "P6M" when fetched via the API.
  // The M has nothing to do with Month, as it is P and M also whichever you select, quarterly/annually/per_six_months etc
  // It is also unknown what the P means either.

  const { hs_recurring_billing_period, recurringbillingfrequency } =
    entry || {};
  let monthsEach = 0;

  // recurring
  switch (recurringbillingfrequency) {
    case "per_three_years":
      monthsEach = 36;
      break;
    case "per_two_years":
      monthsEach = 24;
      break;
    case "annually":
      monthsEach = 12;
      break;

    case "per_six_months":
      monthsEach = 6;
      break;

    case "quarterly":
      monthsEach = 3;
      break;

    case "monthly":
    default:
      monthsEach = 1;
  }

  const intMonths = !hs_recurring_billing_period
    ? 1
    : parseInt(hs_recurring_billing_period.replace(/[PM]/g, ""));

  const repetitions = intMonths / monthsEach;

  if (intMonths % monthsEach) {
    console.warn(
      `WARNING Hubspot product ${
        entry.id
      } has bad configuration: Term (${intMonths}) is not divisable by number of months between charges (${monthsEach}). Term should be (${
        monthsEach * 1
      }, ${monthsEach * 2}, ${monthsEach * 3}...)`,
    );
  }

  // console.log(
  //   "got MONTHS, recurN",
  //   hs_recurring_billing_period,
  //   intMonths,
  //   recurringbillingfrequency
  // );

  // oneoff
  if (!hs_recurring_billing_period || repetitions === 1) {
    return {
      recurring: false,
      repetitions,
      monthsEach,
    };
  }

  // recurring
  return {
    recurring: true,
    monthsEach,
    repetitions,
  };
};

export const scheduleInFuture = (recurrance) => {
  const { monthsEach, repetitions, startDate = phoenixTime() } = recurrance;

  return addMonths(startDate, repetitions * monthsEach);
};

export const phoenixTime = () => {
  const phoenix = new Date().toLocaleString("en-US", {
    timeZone: "America/Phoenix",
  });
  // console.log("phoenixtime", phoenix);
  return new Date(phoenix);
};

export const dateToIso8601YYYYMMDD = (inputDate) => {
  if (!inputDate) {
    return "";
  }
  // date object (local time) -> "2022-10-30", aka MYSQL style
  const mth = "0" + (inputDate.getMonth() + 1);
  const day = "0" + inputDate.getDate();

  const res = `${inputDate.getFullYear()}-${mth.substring(
    mth.length - 2,
    mth.length,
  )}-${day.substring(day.length - 2, day.length)}`;
  return res;
};

export const iso8601YYYYMMDDtoLocalDate = (datestring) => {
  if (!datestring || typeof datestring !== "string") {
    return new Date();
  }
  // take a string 2022-01-01 and make local time out of it.
  // return new Date(datestring); // will assume "2022-01-01 Zulu" = wrong date sometimes
  //        new Date(y,m,d) will return LOCAL time of that date, midnight.
  const parts = datestring.split("-");
  return new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const cardInfoString = (card) =>
  `${card.brand?.toUpperCase()} ****${card.last4} ${
    card.exp_month < 10 ? "0" + card.exp_month : card.exp_month
  }/${card.exp_year}`;

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const ordinal_suffix_of = (i) => {
  if (!i) {
    return "-";
  }
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const priceExplanation = (entry, options = null) => {
  const recurrance = figureRecurrance(entry);
  const monthly = +entry.priceCents || 0;
  const total = monthly * recurrance.repetitions;

  // short version
  if (options && options.short) {
    return `(${formatCents(monthly)}*${recurrance.repetitions})`;
  }

  // long  version
  return `${formatCents(monthly)} (*${
    recurrance.repetitions
  } payments = ${formatCents(total)} total)`;
};

export const stripParentheses = (name) => name.replace(/ *\(.*/, "").trim(); // "Project Next (whatever yada)" => "Project Next"

export const lookupCountry = (code) => {
  return countries.find((country) => country.value === code);
};

export const figureCountryCodeFromCodeOrName = (code) => {
  if (!code) {
    return "US";
  }

  const validCode = countries.map((e) => e.value).includes(code);
  if (validCode) {
    // console.log("found matching country by code", addr.country);
    return code;
  }

  // check by name, sales reps sometimes input that instead of the country code
  const countryNameMatch = countries.find(
    (e) => e.label.trim().toLocaleLowerCase() === code.toLocaleLowerCase(),
  );
  if (countryNameMatch) {
    // console.log("found matching country by name", countryNameMatch);
    return countryNameMatch.value;
  }
  return "US";
};

export const formatAddress = (addressObject, omitCountry = false) => {
  // country is code
  // const address = {
  //   address,
  //   state,
  //   zip,
  //   city,
  //   country,
  // };
  const { address, state, zip, city, country } = addressObject;

  const countryPart =
    !omitCountry && country
      ? " " + lookupCountry(figureCountryCodeFromCodeOrName(country))?.label ||
        country
      : "";
  const addressString = `${address || ""} ${city || ""}, ${state || ""} ${
    zip || ""
  } ${countryPart}`.trim();
  if (addressString === ",") {
    return "";
  }
  return addressString;
};
