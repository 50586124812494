// https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements&html-or-react=react
import {
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useGetProductsQuery,
  useSchedulePaymentMutation,
} from "../store/api";
import AuthContext from "../store/AuthContext";
import { selectDeal, selectEmail } from "../store/cashier";
import { CARD_ELEMENT_OPTIONS } from "../store/constants";
import { getAddressOnly } from "../util/smarty";
import {
  cardInfoString,
  checkCardValidity,
  dateToIso8601YYYYMMDD,
  explainDecline,
  figureRecurrance,
  formatCents,
  iso8601YYYYMMDDtoLocalDate,
  phoenixTime,
  priceExplanation,
  scheduleInFuture,
  stripParentheses,
} from "../util/stripe";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { addDays, parseISO } from "date-fns";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { BsCalendarDate } from "react-icons/bs";
import { ImCheckmark, ImCross, ImWarning } from "react-icons/im"; // stripe setup

import { useDispatch, useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";

import AddCard from "./AddCard";
import AddressSelector from "./AddressSelector";
import CardSelector from "./CardSelector";
import FieldGroup from "./FieldGroup";
import OverlayDialog from "./OverlayDialog";
import ProductDropdown from "./ProductDropdown";
import StyledErrorMessage from "./StyledErrorMessage";
import StyledFormikField from "./StyledFormikField";
import TWDatePicker from "./TWDatePicker";
import VerbalAgreement from "./VerbalAgreement";

// eslint-disable-next-line no-undef
const sandbox = process.env.REACT_APP_sandbox || false;

const validateEntry = (entries, id) => {
  if (!id) {
    return id;
  }
  if (entries.map((entry) => String(entry.id)).includes(id)) {
    return id;
  }
  return ""; // no matching entry
};

const PaymentPlanForm = (props) => {
  // bad card 5128 1234 5678 9010
  // ok card 5128 1234 5678 9011
  // 4242424242424242 succeed+process
  // 4000000000009995 fail insufficient_funds
  // 4000002500003155 authentication required (fail authentication_not_handled)

  const { setMessage } = props;

  // imports
  const stripe = useStripe();
  const elements = useElements();

  // contexts
  const authctx = useContext(AuthContext);
  const { setOverlay, addCardToCustomer } = authctx;
  const [schedulePayment] = useSchedulePaymentMutation();

  const dispatch = useDispatch();

  const { deal, stripeCustomer, stripeCustomerId } = useSelector(
    (state) => state.cashier,
  );
  const { line_item_product_id } = deal || {};
  const { user } = useSelector((state) => state.app);
  const { data: cards = [] } = useGetPaymentMethodsQuery(
    { customerId: stripeCustomerId },
    { skip: !stripeCustomerId },
  );
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();

  const address = getAddressOnly(deal);

  const formRef = useRef();

  const [previewTax, setPreviewTax] = useState(0); // in cents

  const tellTax = (taxDollars) => {
    setPreviewTax(() => taxDollars * 100);
  };

  const defaultPaymentMethod =
    stripeCustomer.invoice_settings?.default_payment_method ||
    stripeCustomer.default_source ||
    "";

  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState(defaultPaymentMethod);

  const [products, setProducts] = useState([]);

  const [doneDialog, setDoneDialog] = useState(false);

  const [addCardErr, setAddCardErr] = useState(null);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const getproductbyid = (selected) => products.find((p) => p.id === selected);

  const totalCostOfPaymentPlan = (product) => {
    if (
      !product ||
      !product.hs_recurring_billing_period ||
      !product.priceCents
    ) {
      return 0;
    }
    const installments = parseInt(
      product.hs_recurring_billing_period.replace(/[PM]/g, ""),
    );
    const monthly = (+product.priceCents || 0) / 100;
    return monthly * installments;
  };

  let { preloadProduct } = props;
  const [selectedProduct, setSelectedProduct] = useState(preloadProduct || "");

  const [selectedPrice, setSelectedPrice] = useState(preloadProduct || ""); // for subscriptions only

  const totalPriceDollars = totalCostOfPaymentPlan(
    getproductbyid(selectedProduct),
  );

  const handlePriceChange = (prod) => {
    const selectedProd = getproductbyid(prod);
    setSelectedProduct(() => prod);
    setChargeTax(() => selectedProd?.has_tax === "Yes");
    setSelectedPrice(() => prod);
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const scheduleMultiple = async (
    chargeProduct,
    remainingCredit,
    inputStartDate,
  ) => {
    const startDate = isValidDate(inputStartDate) ? inputStartDate : new Date();

    let remainingCreditCents = remainingCredit; // count this down over future installments, if too large

    const cardInfo = cards.find((card) => card.id === currentPaymentMethod);
    const card = cardInfo.card;

    const recurrance = figureRecurrance(chargeProduct);
    if (!recurrance.recurring) {
      // nothing to do
      return Promise.resolve({
        warnings: null,
        success: true,
        error: null,
        scheduledPayments: 0,
      });
    }

    let payCents = 0;
    let scheduledPayments = 0;
    let warnings = [];
    for (let i = 0; i < recurrance.repetitions; i++) {
      const scheduleTime = scheduleInFuture({
        monthsEach: recurrance.monthsEach,
        repetitions: i,
        startDate,
      });
      const cardWillBeValid = checkCardValidity(card, scheduleTime);

      payCents = chargeProduct.priceCents;
      let creditedAmountThisTerm = 0;
      if (remainingCreditCents > 0) {
        if (remainingCreditCents > chargeProduct.priceCents) {
          payCents = 0;
          creditedAmountThisTerm = chargeProduct.priceCents;
          remainingCreditCents -= chargeProduct.priceCents;
        } else {
          payCents = chargeProduct.priceCents - remainingCreditCents;
          creditedAmountThisTerm = remainingCreditCents;
          remainingCreditCents = 0;
        }
      }

      // figure term description and status
      let term_description = `Payment ${i + 1}/${recurrance.repetitions}`;
      let status = "scheduled";

      // special logging if this is a full or partial credit payment
      if (creditedAmountThisTerm > 0) {
        if (payCents === 0) {
          status = "success"; // credited in full
        }
        term_description += ` (with a ${formatCents(
          creditedAmountThisTerm,
        )}$ credit)`; // , credited
      }

      if (!cardWillBeValid && payCents > 0) {
        status = "unscheduled";
      }

      const paymentData = {
        customer_id: stripeCustomer.id,
        subscription: true,
        rep_id: user.repid,
        deal_id: deal.id,
        product_id: selectedPrice, // hubspot id of selected VARIANT. seletedProduct is the "product set"
        amount_cents: payCents,
        product_name: `${stripParentheses(
          chargeProduct.name,
        )} ${priceExplanation(chargeProduct, { short: true })}`,
        payment_date: dateToIso8601YYYYMMDD(scheduleTime),
        customer_email: stripeCustomer.email,
        customer_name: stripeCustomer.name,
        card_info: cardInfoString(card),
        payment_method: currentPaymentMethod,
        sandbox,
        status,
        term_description,
        has_tax: chargeProduct.has_tax === "Yes",
      };

      if (!cardWillBeValid) {
        warnings.push(`The card will be invalid for ${term_description}`);
      }
      scheduledPayments++;
      setOverlay(() => `Scheduling ${term_description}`);
      await schedulePayment(paymentData);
    }

    if (remainingCreditCents > 0) {
      console.warn("UNUSED CREDIT LEFT", remainingCreditCents);
    }

    setOverlay(() => "");

    return Promise.resolve({
      warnings: warnings.length ? warnings : null,
      success: true,
      error: null,
      scheduledPayments,
    }); // warnings
  };

  const handleCharge = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!stripe) {
      return;
    }

    try {
      setMessage({
        ok: false,
        text: ``,
      });

      const chargeProduct = getproductbyid(selectedPrice);
      const remainingCreditCents = formRef.current.values.addCredit
        ? Math.round(+formRef.current.values.creditAmount) * 100 // form "should" check it does not exceed the plan total $
        : 0;

      const todayOrScheduled = formRef.current.values.payment_date
        ? parseISO(formRef.current.values.payment_date)
        : new Date();

      const scheduled = await scheduleMultiple(
        chargeProduct,
        remainingCreditCents,
        todayOrScheduled,
      );

      const successMessage = `Successfully set up ${
        scheduled.scheduledPayments
      } payments; approximately ${formatCents(
        previewTax,
      )} in tax will be added to each charge.`;
      switch (scheduled.success) {
        case true: {
          const msg = {
            ok: true,
            text: successMessage,
          };
          if (scheduled.warnings) {
            msg.warningMessage = ` NOTE: ${scheduled.warnings.join(", ")}`;
          }
          setMessage(msg);
          setDoneDialog(msg);

          break;
        }
        default: {
          const msg = {
            ok: true,
            text: `Something went wrong, ${JSON.stringify(scheduled)}`,
          };
          setMessage(msg);
          setDoneDialog(msg);

          break;
        }
      }
      clearForm(); // don't clear the ok/fail user messages
    } catch (ex) {
      const err = JSON.parse(ex.message);

      setOverlay(() => "");

      setMessage(() => ({
        ok: false,
        text: explainDecline(err),
      }));
    }
  };

  const clearForm = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.formClean(true);

    formRef.current.resetForm();
  };

  const clearFormButtonHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    setMessage({ ok: true, text: "" }); // no message is … no message
    clearForm();
  };

  const addCard = async (event) => {
    setAddCardErr(() => null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      const card = elements.getElement(CardElement);

      const addedPaymentMethod = await addCardToCustomer(
        stripeCustomer.name,
        card,
        stripe,
      );

      setCurrentPaymentMethod(() => addedPaymentMethod);
    } catch (ex) {
      const err = JSON.parse(ex.message);

      setAddCardErr(() => explainDecline(err));
    } finally {
      setOverlay(() => "");
    }
  };

  const removeCard = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      setOverlay(() => "Removing card");
      await deletePaymentMethod({ id: currentPaymentMethod });
    } finally {
      setOverlay(() => "");
    }
  };

  const [chargeTax, setChargeTax] = useState(false);
  // watch product selection
  useEffect(() => {
    setChargeTax(() => false);

    // get ALL prods
    const filteredPrices = products
      .filter((p) => p.hs_recurring_billing_period)
      .sort((a, b) =>
        a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1,
      );

    if (filteredPrices.length === 1) {
      setSelectedPrice(filteredPrices[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const { data: { stripePrices: rawProducts } = {} } = useGetProductsQuery();

  useEffect(() => {
    const newProds = (rawProducts ?? []).filter(
      (p) => p.hs_recurring_billing_period,
    );

    setProducts(() => newProds);

    // if product list changes and the selected product is no longer there
    if (newProds && newProds.length && selectedProduct) {
      const validatedProduct = validateEntry(newProds, selectedProduct);
      if (!validatedProduct) {
        setSelectedProduct(() => "");
      }
    }

    // figure and auto select productSET => productID dropdown, and price dropdown
    if (line_item_product_id) {
      const productSet = rawProducts.find(
        (p) => p.id === line_item_product_id,
      )?.product_id;

      const groupProduct = rawProducts.filter(
        (p) => p.product_id === productSet,
      )[0]?.id;

      setSelectedProduct(() => groupProduct); // productSET
      setSelectedPrice(() => line_item_product_id); // reset price selector box
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProducts]);

  const clearCustomerSelection = () => {
    dispatch(selectEmail(""));
    dispatch(selectDeal(null));
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        agree: "",
        addCredit: false,
        creditAmount: 0,
        schedule: false,
        payment_date: "",
      }}
      validationSchema={Yup.object({
        creditAmount: Yup.number()
          .typeError("Must be number")
          .positive("Must be positive")
          .min(0.01, "Must be at least 1 cent")
          .max(totalPriceDollars, "Cannot be more than total plan price"),
        payment_date: Yup.string(),
        agree: Yup.boolean().oneOf([true]),
        schedule: Yup.boolean().oneOf([true, false]),
        addCredit: Yup.boolean().oneOf([true, false]),
        datetime: Yup.string(),
      })}
    >
      {(form) => {
        const {
          errors,
          // touched,
          values,
        } = form;

        // // !selectedProduct ||
        const chargeDisabled =
          !values.agree ||
          (values.addCredit &&
            (errors.creditAmount || values.creditAmount < 0.01)) ||
          !currentPaymentMethod ||
          !cards.find((e) => e.id === currentPaymentMethod) ||
          currentPaymentMethod === "new" ||
          (chargeTax && !address?.address) || // only require address if we are charging tax on selected product
          !selectedPrice ||
          (values.schedule && !values.payment_date);

        // Products dropdown
        // const listProducts = products
        //   .filter((p) => !!p.hs_recurring_billing_period)
        //   .sort((a, b) =>
        //     a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1,
        //   );

        const setDate = (datestr) => {
          form.setFieldValue("payment_date", datestr);
        };

        // find LAST charge date in series
        const chargeProduct = getproductbyid(selectedPrice);

        const recurrance = figureRecurrance(chargeProduct);

        const lastScheduledDate = scheduleInFuture(recurrance);
        const cardWillBeValidOnLastPayment = checkCardValidity(
          cards.find((c) => c.id === currentPaymentMethod)?.card,
          lastScheduledDate,
        );

        const chargeText = values.schedule
          ? `Charged on ${iso8601YYYYMMDDtoLocalDate(
              values.payment_date,
            ).toLocaleDateString(undefined, {
              day: "numeric",
              month: "short",
            })}`
          : "Charged today";

        const chargeAmount =
          (parseInt(getproductbyid(selectedPrice)?.priceCents) || 0) / 100;

        const scheduleToggled = (event) => {
          const newstate = event.target.checked;

          formRef.current.setFieldValue("schedule", newstate);
          setCalendarOpen(() => newstate);
        };

        // we get goodies from Formik that we can use here
        return (
          <Form className="px-4" onSubmit={handleCharge}>
            <ProductDropdown
              selected={selectedPrice}
              onChange={handlePriceChange}
              products={products}
            />

            <div className="mb-2 mt-4 text-blue-400">Pay with</div>
            <CardSelector
              name="card"
              cards={cards}
              paymentMethod={currentPaymentMethod}
              setCurrentSource={setCurrentPaymentMethod}
              removeCard={removeCard}
            />

            <div className={currentPaymentMethod !== "new" ? "hidden" : ""}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>

            {/* Add card component */}
            {!!(currentPaymentMethod === "new") && <AddCard onAdd={addCard} />}
            {addCardErr && (
              <div className="text-center text-red-500">{addCardErr}</div>
            )}
            {!cardWillBeValidOnLastPayment &&
              currentPaymentMethod !== "new" && (
                <div className="my-2 flex bg-amber-100 p-2 text-center text-amber-700">
                  <ImWarning />
                  The selected card will be invalid for one or more of the
                  installments.
                </div>
              )}

            {/* Add credit  */}
            {/* checkbox */}
            <div className="my-2 flex flex-row items-center pt-2 text-justify text-sm text-gray-400">
              <div className="grow">
                <Field
                  type="checkbox"
                  id="creditCheckbox"
                  name="addCredit"
                  className="mx-1 cursor-pointer px-1"
                />
                <label
                  htmlFor="creditCheckbox"
                  className="mx-1 cursor-pointer px-1"
                >
                  Add credit
                </label>
              </div>
            </div>
            {values.addCredit && (
              <>
                <div className="my-2 flex flex-col">
                  <StyledFormikField
                    type="text"
                    className={twMerge("p-2")}
                    name="creditAmount"
                  />
                </div>
                {errors.creditAmount && (
                  <StyledErrorMessage name="creditAmount" />
                )}
              </>
            )}

            {/* Schedule payments input field */}
            <div className="mb-2 mt-4 text-blue-400">Schedule start date</div>

            <div className="flex flex-col">
              <div className="flex flex-row items-center pt-2 text-justify text-sm text-gray-400">
                <div className="grow text-left">
                  <Field
                    type="checkbox"
                    id="scheduledCheckbox"
                    name="schedule"
                    onChange={scheduleToggled}
                    className="mx-1 cursor-pointer px-1"
                  />
                  <label
                    htmlFor="scheduledCheckbox"
                    className="mx-1 cursor-pointer px-1"
                  >
                    {`Schedule plan start date ${
                      values.schedule
                        ? "for: " + (values.payment_date || "(select a date)")
                        : "for a later date"
                    }`}
                  </label>
                </div>
                {values.schedule && (
                  <div
                    className="cursor-pointer"
                    onClick={() => setCalendarOpen(() => !calendarOpen)}
                  >
                    <BsCalendarDate />
                  </div>
                )}
              </div>
              {calendarOpen && (
                <TWDatePicker
                  className="self-center"
                  onChange={setDate}
                  onClose={() => setCalendarOpen(() => false)}
                  minDate={addDays(phoenixTime(), 1)}
                  defaultDate={values.payment_date}
                />
              )}
            </div>
            <AddressSelector
              chargeText={chargeText}
              chargeAmount={chargeAmount}
              creditAmount={values.addCredit ? values.creditAmount : 0}
              product={getproductbyid(selectedPrice)}
              tellTax={tellTax}
            />

            <VerbalAgreement />

            <FieldGroup className="content-center">
              <button
                disabled={chargeDisabled}
                className={twMerge(
                  "my-2 w-full p-2 font-semibold text-white",
                  chargeDisabled ? "bg-gray-400" : "bg-blue-400",
                )}
              >
                {values.schedule ? "Schedule plan" : "Start plan now"}
              </button>
            </FieldGroup>
            {doneDialog && (
              <OverlayDialog
                onYes={() => setDoneDialog(() => false)}
                onNo={clearCustomerSelection}
                ok={doneDialog.ok}
                icon={doneDialog.ok ? ImCheckmark : ImCross}
                message={doneDialog.text}
                warningMessage={doneDialog.warningMessage}
                message2={"Keep working with this customer?"}
              />
            )}

            <FieldGroup className="content-center">
              <button
                className="my-2 w-full rounded border border-blue-500 p-2 font-semibold text-blue-400"
                onClick={clearFormButtonHandler}
              >
                Clear
              </button>
            </FieldGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentPlanForm;
