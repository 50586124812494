import {
  useGetPaymentMethodsQuery,
  useSchedulePaymentMutation,
  useUpdatePaymentMutation,
} from "../store/api";
import AuthContext from "../store/AuthContext";
import {
  CARD_ELEMENT_OPTIONS,
  PS_SCHEDULED,
  stripePromise,
} from "../store/constants";
import {
  cardInfoString,
  checkCardValidity,
  dateToIso8601YYYYMMDD,
  explainDecline,
  phoenixTime,
} from "../util/stripe";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { addMonths } from "date-fns";
import { Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";

import AddCard from "./AddCard";
import Backdrop from "./Backdrop";
import Box from "./Box";
import CardSelector from "./CardSelector";
import StyledErrorMessage from "./StyledErrorMessage";
import StyledFormikField from "./StyledFormikField";
import TWDatePicker from "./TWDatePicker";

const FieldTitle = (props) => (
  <div className="mt-4 text-blue-500">{props.children}</div>
);

// actual form needs to be wrapped in an <Elements…
const ModificationForm = (props) => {
  // contexts
  const { payment } = props;

  const [updatePayment] = useUpdatePaymentMutation();
  const [schedulePayment] = useSchedulePaymentMutation();

  const authctx = useContext(AuthContext);
  const { addCardToCustomer, setOverlay } = authctx;

  const { stripeCustomer, stripeCustomerId } = useSelector(
    (state) => state.cashier,
  );

  const { data: cards = [] } = useGetPaymentMethodsQuery(
    { customerId: stripeCustomerId },
    { skip: !stripeCustomerId },
  );
  const formRef = useRef();

  // stripe stuff
  const elements = useElements();
  const stripe = useStripe();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    payment.payment_method,
  );
  const [addCardErr, setAddCardErr] = useState(null);

  const getPaymentMethod = (paymentMethodId) =>
    cards.find((c) => c.id === paymentMethodId);

  const getCardInfo = (paymentMethodId) => {
    const paymentMethod = getPaymentMethod(paymentMethodId);
    if (!paymentMethod) {
      return "";
    }
    const { card } = paymentMethod;
    return cardInfoString(card);
  };

  const getCardExpiry = (paymentMethodId) => {
    const paymentMethod = getPaymentMethod(paymentMethodId);
    if (!paymentMethod) {
      return "";
    }
    const { card } = paymentMethod;
    const mth = "0" + card.exp_month;
    const cardinfo = `${card.exp_year}-${mth.substring(
      mth.length - 2,
      mth.length,
    )}-01`;
    return cardinfo;
  };

  // watch payment method changes
  useEffect(() => {
    formRef.current.setFieldValue("payment_method", selectedPaymentMethod);
    formRef.current.setFieldValue(
      "card_info",
      getCardInfo(selectedPaymentMethod),
    );
    formRef.current.setFieldValue(
      "carddata",
      getCardExpiry(selectedPaymentMethod),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  const minDate = phoenixTime();

  // const defaultDate = new Date(payment.payment_date);
  // const defaultDate = new Date(payment.payment_date);
  useEffect(() => {
    const minDateStr = minDate.toISOString();
    if (new Date(payment.payment_date) < minDate) {
      payment.payment_date = minDateStr;
      setDate(minDateStr); // set form value too. defaultDate is value for
    } else {
      setDate(payment.payment_date); // set form value too. defaultDate is value for
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = () => {
    props.onClose();
  };

  const save = async () => {
    // console.log("save and close", event); //  Backdrop goes straight through (?)

    const {
      amount,
      payment_method,
      payment_date: full_payment_date,
      card_info,
      repetitions,
    } = formRef.current.values;

    const payment_date = full_payment_date.substring(0, 10); // strip any time info

    const amount_cents = Math.round(parseFloat(amount) * 100);

    const newPayment = {
      ...payment,
      amount_cents,
      payment_method,
      card_info,
      repetitions,
      payment_date,
      status: PS_SCHEDULED, // this dialog will never allow unscheduled
    };

    props.onClose();

    if (payment.id) {
      setOverlay("Saving change…");
      await updatePayment({
        id: payment.id,
        payment_date,
        payment_method,
        card_info,
        amount_cents,
        status: PS_SCHEDULED,
      });
    } else {
      if (newPayment.repetitions > 1) {
        // several payments
        setOverlay(`Scheduling ${newPayment.repetitions} payments…`);
        const start_date = new Date(newPayment.payment_date);

        for (let add = 0; add < newPayment.repetitions; add++) {
          const payment_date = dateToIso8601YYYYMMDD(
            addMonths(start_date, add),
          );
          await schedulePayment({ ...newPayment, payment_date });
        }
      } else {
        // 0 or 1 payments
        setOverlay("Scheduling new payment…");

        await schedulePayment(newPayment);
      }
    }
    setOverlay("");
  };

  const setDate = (datestr) => {
    formRef.current.setFieldValue("payment_date", datestr);
  };

  const addCard = async (event) => {
    setAddCardErr(() => null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      setOverlay(() => "");

      const card = elements.getElement(CardElement);

      const addedPaymentMethod = await addCardToCustomer(
        stripeCustomer.name,
        card,
        stripe,
      );

      setSelectedPaymentMethod(() => addedPaymentMethod);
    } catch (ex) {
      console.error("addCard error", ex);
      const err = JSON.parse(ex.message);

      setAddCardErr(() => explainDecline(err));
    } finally {
      setOverlay(() => "");
    }
  };

  return (
    <Formik
      innerRef={formRef}
      onSubmit={save}
      initialValues={{
        amount: payment.amount_cents / 100,
        payment_date: payment.payment_date,
        payment_method: payment.payment_method,
        card_info: getCardInfo(selectedPaymentMethod),
        carddata: getCardExpiry(selectedPaymentMethod), // for inputting into payment date validator
        repetitions: 1,
      }}
      validationSchema={Yup.object({
        amount: Yup.number()
          .typeError("Must be number")
          .positive("Must be positive")
          .required("Amount is required")
          .min(0.5, "Minimum 50 cents"),
        payment_method: Yup.string().not(["new"]), // invalid when set to New
        payment_date: Yup.date(),
        // .dateInFuture("Date must be tomorrow or later"), // edit needs to allow tomorrow
        repetitions: Yup.number()
          .typeError("Must be number")
          .positive("Must be positive"),
      })}
    >
      {(form) => {
        const { errors, touched, values } = form;

        touched.payment_date = true;
        touched.amount = true;

        const payMethod = getPaymentMethod(selectedPaymentMethod);
        const cardWillBeValid = checkCardValidity(
          payMethod?.card,
          new Date(values.payment_date),
        );

        const saveDisabled = !!(
          !cardWillBeValid ||
          errors.amount ||
          errors.payment_date ||
          (errors.payment_method && selectedPaymentMethod === "new") ||
          selectedPaymentMethod === "new"
        );

        return (
          /* onChange={onChange} */
          <Form className="px-4">
            {/* Normal edit */}
            {!payment.retry && !payment.copy && (
              <div className="font-bold">Edit scheduled payment</div>
            )}

            {/* Schedule retry */}
            {!!payment.retry && (
              <div className="text-red-500">
                RETRY PAYMENT {payment.term_description}
              </div>
            )}

            {/* Schedule copy */}
            {!!payment.copy && (
              <div className="text-green-700">
                COPY PAYMENT {payment.term_description}
              </div>
            )}

            <FieldTitle>Scheduled date</FieldTitle>
            <TWDatePicker
              className="self-center"
              onChange={setDate}
              minDate={minDate}
              defaultDate={payment.payment_date}
            />
            <StyledErrorMessage name="payment_date" />

            <FieldTitle>Card</FieldTitle>
            <CardSelector
              name="card"
              cards={cards}
              paymentMethod={selectedPaymentMethod}
              setCurrentSource={setSelectedPaymentMethod}
            />
            {/* Add card or request link */}
            <div className={selectedPaymentMethod !== "new" ? "hidden" : ""}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            {addCardErr && (
              <div className="text-center text-red-500">{addCardErr}</div>
            )}
            {!!(selectedPaymentMethod === "new") && <AddCard onAdd={addCard} />}

            {!cardWillBeValid && selectedPaymentMethod !== "new" && (
              <div className="text-red-500">
                Selected card will have expired by the selected date
              </div>
            )}

            <FieldTitle>Amount</FieldTitle>
            <StyledFormikField name="amount" className="w-full" />
            <StyledErrorMessage name="amount" />

            <div className="mt-8 flex justify-between">
              <div
                onClick={close}
                className="cursor-pointer rounded border p-2"
              >
                CANCEL
              </div>
              <div
                onClick={
                  saveDisabled ? null : () => formRef.current.submitForm()
                }
                disabled={saveDisabled}
                className={twMerge(
                  "cursor-pointer rounded bg-blue-500 p-2 text-white",
                  saveDisabled && "bg-gray-400",
                )}
              >
                SAVE
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

// modal form wrapper
const EditScheduledPayment = (props) => {
  const avoidBackdropClick = (event) => event.stopPropagation();

  return createPortal(
    <>
      <Backdrop onClick={props.onClose} className="overflow-y-auto">
        <Box
          outerClassName="mx-auto my-auto"
          className="w-full min-w-[500px] bg-white"
          onClick={avoidBackdropClick}
          onMouseDown={avoidBackdropClick}
          onMouseUp={avoidBackdropClick}
        >
          <Elements stripe={stripePromise}>
            <ModificationForm
              payment={props.payment}
              onClose={props.onClose}
              onDelete={props.onDelete}
            />
          </Elements>
        </Box>
      </Backdrop>
    </>,
    document.getElementById("modaldiv"),
  );
};
export default EditScheduledPayment;
