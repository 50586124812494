import FormWrapper from "../components/FormWrapper";
import SelectDeal from "../components/SelectDeal";
import {
  useGetDealQuery,
  useGetHubspotContactQuery,
  useGetOrCreateStripeCustomerMutation,
  useGetStripeCustomerQuery,
  useUpdateHubspotContactMutation,
} from "../store/api";
import {
  selectDeal,
  selectEmail,
  selectStripeCustomer,
  setContact,
  setDeal,
  setStripeCustomer,
} from "../store/cashier";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Cashier.css";

import Box from "../components/Box";
import { fullName } from "../util/stripe";

const Cashier = () => {
  const dispatch = useDispatch();

  const [getOrCreateStripeCustomer] = useGetOrCreateStripeCustomerMutation();
  const [updateContact] = useUpdateHubspotContactMutation();

  const { selectedDeal, selectedEmail, stripeCustomerId } = useSelector(
    (state) => state.cashier,
  );

  // redux query watching selected deal ID
  const { data: dealFetched, error: dealFetchError } = useGetDealQuery(
    { id: selectedDeal },
    {
      skip: !selectedDeal,
    },
  );

  // Fdux query watching selected hubspot contact
  const { data: contact = null } = useGetHubspotContactQuery(
    {
      email: selectedEmail,
    },
    { skip: !selectedEmail },
  );

  const getStripeCustomerAndUpdateHubspotContact = async (id, newFullName) => {
    // TODO lookup stripeCustomerId -By Mail for now-
    const { data: fetchedStripeCustomer } = await getOrCreateStripeCustomer({
      email: selectedEmail,
      fullName: newFullName,
    });

    if (fetchedStripeCustomer?.id) {
      updateContact({
        id,
        properties: { gateway_stripe_customer_id: fetchedStripeCustomer.id },
      });
    }
  };

  // WATCH: figure stripe customer id from hubspot contact data
  useEffect(() => {
    if (
      (!selectedEmail && !selectedDeal) ||
      !contact ||
      contact.email !== selectedEmail
    ) {
      return;
    }

    const { id, gateway_stripe_customer_id } = contact;
    dispatch(setContact(contact));

    if (!gateway_stripe_customer_id) {
      console.warn(
        "Got contact but NO stripe customer id. Lookup oldest by email (or create new) and re-fetch",
      );
      getStripeCustomerAndUpdateHubspotContact(id, fullName(contact));
      return;
    }

    dispatch(selectStripeCustomer(gateway_stripe_customer_id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, selectedEmail, dispatch]);

  // WATCH: load selected deal to cashier state
  useEffect(() => {
    if (dealFetchError || !selectedDeal) {
      dispatch(setDeal({}));
      dispatch(selectDeal(null));
      return;
    }
    if (+dealFetched?.id === +selectedDeal) {
      dispatch(selectEmail(dealFetched.email));
      dispatch(setDeal(dealFetched));
    }
  }, [dealFetched, dealFetchError, selectedDeal, dispatch]);

  // When we have email, get stripe customer
  const {
    data: stripeCustomerQuery,
    error: stripeError,
    isFetching: isFetchingStripeCustomer,
    isError: stripeCustomerFetchingFailed,
  } = useGetStripeCustomerQuery(
    {
      id: stripeCustomerId,
      email: selectedEmail,
      fullName: fullName(contact),
    },
    {
      skip: !stripeCustomerId || !selectedEmail || !contact?.id,
    },
  );

  const { stripeCustomer: stateCustomer } = useSelector(
    (state) => state.cashier,
  );

  // WATCH: load stripecustomer to cashier state
  useEffect(() => {
    if (stripeError || !stripeCustomerId) {
      if (stripeCustomerQuery?.id) {
        dispatch(setStripeCustomer({}));
      }
      return;
    }

    if (
      !selectedEmail ||
      !stripeCustomerQuery?.id ||
      stripeCustomerQuery.id === stateCustomer?.id // no need to re-trig
    ) {
      return;
    }

    dispatch(setStripeCustomer(stripeCustomerQuery));
  }, [
    stripeCustomerId,
    stripeCustomerQuery,
    stateCustomer?.id,
    stripeError,
    selectedEmail,
    selectedDeal,
    contact,
    dispatch,
  ]);

  if (selectedDeal && +dealFetched?.id !== +selectedDeal) {
    return <Box className="text-center">Fetching deal {selectedDeal}…</Box>;
  }

  if (!selectedDeal) {
    return <SelectDeal />;
  }

  if (!stripeCustomerQuery?.id || isFetchingStripeCustomer) {
    return (
      <Box className="text-center">
        Looking up stripe customer {selectedEmail}…
        {JSON.stringify(stripeCustomerQuery)}
        {isFetchingStripeCustomer ? "fetching" : "fetched"}
      </Box>
    );
  }

  // failed to retrieve / need to create stripe user but couldn't
  if (!stripeCustomerQuery?.id && stripeCustomerFetchingFailed) {
    // FATAL error, should never happen
    return (
      <Box className="text-center">
        Failed to load/create stripe user {fullName(contact)} ({selectedEmail})
        {":("} Please{" "}
        <a
          className="underline"
          href="https://forms.gle/7TxU558UV3d1VvtP8"
          target="_blank"
          rel="noreferrer"
        >
          tell the tech department on this link. Please include a screenshot
          {JSON.stringify(stripeError)}
        </a>
      </Box>
    );
  }

  // Got 'nuff data to show payment forms
  return <FormWrapper />;
};

export default Cashier;
