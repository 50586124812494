import { dt } from "../util/stripe";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { differenceInDays } from "date-fns";

import {
  RT_DEAL,
  RT_DEALSFOREMAIL,
  RT_HUBSPOTCONTACT,
  RT_PAYMENTMETHODS,
  RT_SCHEDULEDPAYMENT,
  RT_STRIPECUSTOMER,
} from "./constants";

const getBaseUrl = () => {
  // eslint-disable-next-line no-undef
  return process.env.REACT_APP_apiEndpoint;
};

const sortByPaymentDate = (response) => {
  if (!response?.result?.length) {
    return [];
  }

  const now = new Date();
  return response.result
    .sort((a, b) => dt(a.payment_date) - dt(b.payment_date))
    .map((payment) => {
      payment.dateDifference = differenceInDays(dt(payment.payment_date), now);

      if (payment.dateDifference === 0) {
        payment.dateDifference = "";
        return payment;
      }

      if (Math.abs(payment.dateDifference) > 30) {
        payment.dateDifference = Math.round(payment.dateDifference / 30) + "m";
        return payment;
      }

      if (Math.abs(payment.dateDifference) > 7) {
        payment.dateDifference = Math.round(payment.dateDifference / 7) + "w";
        return payment;
      }

      payment.dateDifference += "d";
      return payment;
    });
};

const backendApi = createApi({
  reducerPath: "backendApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: async (
      headers,
      // , { getState }
    ) => {
      try {
        if (window?.Clerk?.session) {
          const token = await window.Clerk.session.getToken();
          if (token) {
            headers.set("Authorization", `Bearer ${token}`);
          }
        }
      } catch (err) {
        //
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDeal: builder.query({
      query: (params) => `deal?deal_id=${params.id}`, // NOTE query takes only 1 param, so if the api call has multiple, we need to pass an object and destructure here
      providesTags: [RT_DEAL],
    }),

    createDeal: builder.mutation({
      query: (params) => ({
        url: `/deal`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: [RT_DEALSFOREMAIL],
    }),
    updateDeal: builder.mutation({
      query: (params) => ({
        url: `/deal`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: [RT_DEAL],
    }),
    getStripeCustomer: builder.query({
      query: (params) =>
        `/customer/getOldestOrCreate?id=${params.id}&email=${encodeURIComponent(
          params.email,
        )}&fullName=${params.fullName || ""}`,
      providesTags: [RT_STRIPECUSTOMER],
    }),
    getOrCreateStripeCustomer: builder.mutation({
      query: (params) =>
        `/customer/getOldestOrCreate?email=${encodeURIComponent(
          params.email,
        )}&fullName=${params.fullName || ""}`,
      invalidatesTags: [RT_STRIPECUSTOMER],
    }),
    getProducts: builder.query({
      // eslint-disable-next-line no-unused-vars
      query: () => `/products`,
    }),
    getSubscription: builder.query({
      query: (params) => `/subscription/${params.id}`,
    }),

    getPaymentMethods: builder.query({
      query: (params) => `/paymentMethods/${params.customerId}`,
      transformResponse: (response) => {
        if (response?.data?.length) {
          return response.data;
        }
        return [];
      },
      providesTags: [RT_PAYMENTMETHODS],
    }),
    deletePaymentMethod: builder.mutation({
      query: (params) => ({
        url: `/paymentMethod/${params.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RT_PAYMENTMETHODS],
    }),

    attachPaymentMethod: builder.mutation({
      query: (params) => ({
        url: `/paymentMethod/${params.paymentMethodId}`,
        method: "PATCH",
        body: {
          customerId: params.customerId,
        },
      }),
      transformResponse: (response) => response?.attachment?.id,
      transformErrorResponse: (...args) => {
        console.error("attachPaymentMethod error", args);
        return args[0].data;
      },
      invalidatesTags: [RT_PAYMENTMETHODS],
    }),
    getDealsForEmail: builder.query({
      query: (params) => `contactEmail/${params.email}/deals`,
      transformResponse: (response) => response?.deals || [],
      providesTags: [RT_DEALSFOREMAIL],
    }),
    getPortalLink: builder.query({
      query: ({ customerId }) => ({
        url: `/customer/${customerId}/freshPortalsession`,
        method: "POST",
      }),
    }),
    getHubspotContact: builder.query({
      query: (params) => `/contact/${params.email}`,
      providesTags: [RT_HUBSPOTCONTACT],
    }),
    getHubspotContactById: builder.query({
      query: (params) => `/contactById/${params.email}`,
      providesTags: [RT_HUBSPOTCONTACT],
    }),
    createTaxTransaction: builder.mutation({
      // params are {amount, address}
      query: (params) => ({
        url: `/taxTransaction`,
        method: "POST",
        body: params,
      }),
      transformResponse: (
        response,
        // meta, arg
      ) => ({
        id: response.id,
        total: response.totalTax,
        detail: response.summary.map((entry) => ({
          jurisdiction: `${entry.jurisName} ${entry.jurisType}`,
          taxName: entry.taxName,
          rate: entry.rate,
          tax: entry.tax,
        })),
      }),
    }),
    updateHubspotContact: builder.mutation({
      query: (params) => ({
        url: `/contactById/${params.id}`,
        method: "PATCH",
        body: params.properties,
      }),
      invalidatesTags: [RT_HUBSPOTCONTACT],
    }),
    createHubspotContact: builder.mutation({
      query: (params) => ({
        url: `/contact`,
        method: "POST",
        body: params,
      }),
      // we're too fast for this, must timeout ourselves
      // invalidatesTags: [RT_HUBSPOTCONTACT],
    }),
    searchHubspotContacts: builder.query({
      query: (params) => `/contact/${params.email}/search`,
      providesTags: [RT_HUBSPOTCONTACT],
    }),
    getScheduledPayments: builder.query({
      query: (params) => `/scheduledPayments?customerId=${params.customerId}`,
      transformResponse: (response) => sortByPaymentDate(response),
      providesTags: [RT_SCHEDULEDPAYMENT],
    }),
    updatePayment: builder.mutation({
      query: (params) => ({
        url: `/scheduledPayment/${params.id}`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: [RT_SCHEDULEDPAYMENT],
    }),
    chargeCents: builder.mutation({
      query: (params) => ({
        url: `/chargeAmount`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: [RT_SCHEDULEDPAYMENT],
    }),
    schedulePayment: builder.mutation({
      query: (params) => ({
        url: `/scheduledPayment`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: [RT_SCHEDULEDPAYMENT],
    }),
  }),
});

export default backendApi;

export const {
  useGetDealQuery,
  useUpdateDealMutation,
  useGetStripeCustomerQuery,
  useGetOrCreateStripeCustomerMutation,
  useGetDealsForEmailQuery,
  useGetSubscriptionQuery,

  // deprecate non-lazy
  useGetPortalLinkQuery,
  useLazyGetPortalLinkQuery,
  useGetHubspotContactQuery,
  useUpdateHubspotContactMutation,
  useGetScheduledPaymentsQuery,
  useUpdatePaymentMutation,
  useSchedulePaymentMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useAttachPaymentMethodMutation,
  useSearchHubspotContactsQuery,
  useGetProductsQuery,
  useChargeCentsMutation,
  useCreateHubspotContactMutation,
  useCreateDealMutation,
  useLazySearchHubspotContactsQuery,
  useCreateTaxTransactionMutation,
} = backendApi;
