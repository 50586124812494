import useDebouncedInput from "../hooks/useDebouncedInput";
import {
  useUpdateDealMutation,
  useUpdateHubspotContactMutation,
} from "../store/api";
import AuthContext from "../store/AuthContext";
import { getAddressOnly, smartyToInternalAddress } from "../util/smarty";
import {
  countries,
  figureCountryCodeFromCodeOrName,
  formatAddress,
} from "../util/stripe";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";

import Backdrop from "./Backdrop";
import StyledFormikField from "./StyledFormikField";

const SelectedAddress = ({ address }) => (
  <div className="m-4 rounded border border-dotted border-black bg-yellow-100 p-2 text-center font-bold">
    {formatAddress(address)}
  </div>
);

const currentDealAddress = (deal) => {
  const addr = getAddressOnly(deal);
  const { address, state, zip, city, country } = addr;
  return address || state || zip || city || country
    ? { ...addr, country: country || "US" }
    : null;
};

const AddressLookupDialog = (props) => {
  const { onClose, address: inputAddressObject } = props;
  const address = formatAddress(inputAddressObject, true);

  const ctx = useContext(AuthContext);

  const { autocompleteAddress } = ctx;
  const [updateDeal] = useUpdateDealMutation();
  const [updateContact] = useUpdateHubspotContactMutation();

  const { contact } = useSelector((state) => state.cashier);
  const { deal } = useSelector((state) => state.cashier);

  const [addr, setAddr] = useDebouncedInput();

  const formRef = useRef();
  const searchFieldRef = useRef();
  const copyHubspotCheckboxRef = useRef();

  // NOTE: by necessity, TWO different address object formats are used in this component:

  // Smarty format address object, with some Smarty internals, are used on these states:
  const [hits, setHits] = useState([]);
  // {
  //     "street_line": "32634 N Cherry Creek Rd",
  //     "secondary": "",
  //     "city": "Queen Creek",
  //     "state": "AZ",
  //     "zipcode": "85142",
  //     "entries": 0
  //   }

  // "Hubspot" format: (Both Deal and Contact have these 5 string fields)
  const [selectedAddress, setSelectedAddress] = useState(null);
  // {
  //     address
  //     city
  //     state
  //     zip
  //     country
  // }

  useEffect(() => {
    if (deal) {
      // console.log("set address from deal", deal, currentDealAddress(deal));
      setSelectedAddress(() => currentDealAddress(deal));
    }
  }, [deal]);

  const selectAddress = (address) => {
    // console.log("SELECT", address);
    setSelectedAddress(() => smartyToInternalAddress(address));
  };

  const saveAddressToDeal = (address) => {
    updateDeal({ id: deal.id, properties: address });
    onClose();
  };

  const copyAddressToHubspotContact = (selected) => {
    updateContact({ id: contact.id, properties: getAddressOnly(selected) });
  };

  const onSave = () => {
    if (copyHubspotCheckboxRef.current.checked) {
      copyAddressToHubspotContact(selectedAddress);
    }
    saveAddressToDeal(selectedAddress);
  };

  const copyHubspotContactAddress = () => {
    const address = getAddressOnly(contact); // don't include country

    address.country = countryCodeFromDeal(contact);

    setSelectedAddress(address);

    formRef.current.setFieldValue("country", address.country);

    formRef.current.setFieldValue("address", formatAddress(contact, true));
  };

  const setManualAddress = () => {
    setSelectedAddress({
      address: formRef.current.values.address.trim(),
      country: formRef.current.values.country,
      state: null,
      zip: null,
      city: null,
    });
  };

  const refineAddress = (entry) => {
    const { street_line, secondary, entries, city, state, zipcode } = entry;

    // smarty needs this specific format for the 'selected' field:
    const search = `${street_line} ${secondary}`; // reruns up to first parenthesis
    const selected = `${street_line} ${secondary} (${entries}) ${city}, ${state} ${zipcode}`;

    // formRef.current.setFieldValue("address", search);

    autocompleteAddress({
      search,
      selected,
      country: formRef.current.values.country,
    }).then((addrHits) => {
      setHits(() =>
        addrHits?.suggestions && addrHits.suggestions.length
          ? addrHits.suggestions
          : [],
      );
    });

    // focus back to keep typing after refine
    const el = document.getElementById("addressearch");
    el.focus();
  };

  // debounce typing in search field
  const lookupAddress = async (value) => {
    const country = formRef.current.values.country;
    // const address = event.target.value;

    // formRef.current.setFieldValue("address", address);
    setSelectedAddress(() => null); // clear on typing
    setAddr(value, { ...address, country });
    // setAddr(event.target.value, { ...address, country });
  };

  const handleCountryDropdown = (event) => {
    const country = event.target.value;
    formRef.current.setFieldValue("country", country);
    setSelectedAddress(() => null); // clear Confirmed address on country select
  };

  useEffect(() => {
    if (addr && addr.trim() !== "") {
      autocompleteAddress({
        search: addr,
        selected: null,
        country: formRef.current.values.country,
      }).then((addrHits) => {
        setHits(() =>
          addrHits?.suggestions && addrHits.suggestions.length
            ? addrHits.suggestions
            : [],
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addr]);

  const countryLabel = (country) => {
    const entry = countries.find((e) => e.value === country);
    return entry?.label ?? "(country)";
  };

  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  const stopClick = (event) => {
    event.stopPropagation();
    setMouseDownOnBackdrop(() => false);
  };

  const countryCodeFromDeal = (deal) => {
    const addr = getAddressOnly(deal);
    return figureCountryCodeFromCodeOrName(addr?.country);
  };

  const mouseUpOnBackdrop = () => {
    if (mouseDownOnBackdrop) {
      onClose();
    }
  };

  return createPortal(
    <>
      <Backdrop />
      <div
        className="fixed z-[2] flex size-full flex-col items-center justify-center"
        onMouseUp={mouseUpOnBackdrop}
        onMouseDown={() => setMouseDownOnBackdrop(() => true)}
      >
        <div
          className="flex max-h-[90%] w-9/12 flex-col rounded bg-white p-4 lg:w-6/12 xl:w-4/12"
          onMouseUp={stopClick}
          onMouseDown={stopClick} // prevent "click-formdiv+hold+drag-mouse-onto-backdrop-then-release" becoming a "click" event on myself
        >
          <Formik
            innerRef={formRef}
            initialValues={{
              address,
              country: countryCodeFromDeal(deal),
            }}
            validationSchema={Yup.object({
              address: Yup.string(),
              country: Yup.string(),
            })}
          >
            {(form) => {
              const { values } = form;
              const manualAddress = [
                values.address,
                countryLabel(values.country),
              ].join(", ");
              return (
                <Form className="max-h-full overflow-auto">
                  <div className="sticky top-0 bg-white">
                    <div className="mb-2 text-blue-400">Country</div>

                    <Field
                      as="select"
                      name="country"
                      id="creditCheckbox"
                      className="mx-1 w-full cursor-pointer px-1"
                      onChange={handleCountryDropdown}
                    >
                      {countries.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Field>

                    <div className="my-2 text-blue-400">Address</div>

                    <StyledFormikField
                      innerref={searchFieldRef}
                      id="addressearch"
                      name="address"
                      className="w-full"
                      tellChange={lookupAddress}
                    ></StyledFormikField>
                    <div
                      /* className="p-2 my-2 font-semibold text-center text-white bg-blue-400 cursor-pointer" */
                      className="my-2 cursor-pointer py-2 text-sm underline"
                      onClick={setManualAddress}
                    >
                      Manually accept input address &quot;{manualAddress}&quot;
                    </div>
                    <div
                      /* className="p-2 my-2 font-semibold text-center text-white bg-blue-400 cursor-pointer" */
                      className="my-2 cursor-pointer py-2 text-sm underline"
                      onClick={copyHubspotContactAddress}
                    >
                      Use Hubspot Contact address &quot;{formatAddress(contact)}
                      &quot;
                    </div>
                  </div>
                  {!!hits.length && !selectedAddress && (
                    <div className="text-center">
                      or select a verified address:
                      <ul className="border border-gray-500">
                        {!selectedAddress &&
                          hits.map((hit, index) => (
                            <li
                              key={hit.address + "_" + index}
                              className={twMerge(
                                "cursor-pointer p-1",
                                index % 2 === 0 && "bg-slate-100",
                              )}
                              title={hit.entries > 1 ? "See units" : "Select"}
                              onClick={
                                hit.entries > 1
                                  ? () => refineAddress(hit)
                                  : () => selectAddress(hit)
                              }
                            >
                              {hit.entries > 1
                                ? `… ${hit.street_line} (${hit.entries}x${hit.secondary}…) ${hit.city}, ${hit.state} ${hit.zipcode}`
                                : formatAddress(smartyToInternalAddress(hit))}
                              {/* : `→ ${hit.street_line} ${hit.secondary} ${hit.city}, ${hit.state} ${hit.zipcode}`} */}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>

          {selectedAddress && <SelectedAddress address={selectedAddress} />}

          <div className="flex gap-x-2 p-2 align-middle">
            <input
              type="checkbox"
              ref={copyHubspotCheckboxRef}
              id="copyToContact"
              className="cursor-pointer"
            ></input>
            <label className="cursor-pointer" htmlFor="copyToContact">
              Copy to Contact address
            </label>
          </div>
          <div
            className={twMerge(
              "my-2 cursor-pointer bg-blue-400 p-2 text-center font-semibold text-white",
              !selectedAddress && "bg-gray-400",
            )}
            onClick={selectedAddress ? onSave : null}
          >
            Save{/*  {JSON.stringify(selectedAddress)} */}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modaldiv"),
  );
};

export default AddressLookupDialog;
