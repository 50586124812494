import { useGetHubspotContactQuery } from "../store/api";
import {
  useAssignWorkflowMethodMutation,
  useGetWorkflowsQuery,
  useGetWorkflowTemplatesQuery,
} from "../store/onboarding-api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

import FieldGroup from "./FieldGroup";
import WorkflowDropdown from "./WorkflowDropdown";

const onboardingServerUrl = process.env.REACT_APP_ONBOARDING_SERVER;
const orderformUrl = process.env.REACT_APP_ORDERFORM_URL;

// TODO this is hardcoded stuff
const parseStatus = (status) => {
  switch (status) {
    case "book": {
      return { finished: true, label: "Booking appointment" };
    }
    case "new": {
      return { finished: false, label: "New" };
    }
    case "loaded": {
      return { finished: false, label: "Loaded" };
    }
    case "card": {
      return { finished: false, label: "Adding card" };
    }
    case "agreement": {
      return { finished: false, label: "Signing agreement" };
    }
    case "video": {
      return { finished: false, label: "Watching video" };
    }
    default: {
      return { finished: false, label: "-?-" };
    }
  }
};

const BoardingPane = () => {
  const [assignWorkflowMethod] = useAssignWorkflowMethodMutation();
  const { selectedEmail, selectedDeal } = useSelector((state) => state.cashier);
  const [sessionUrl, setSessionUrl] = useState("");
  const { data: contact = null } = useGetHubspotContactQuery(
    {
      email: selectedEmail,
    },
    { skip: !selectedEmail },
  );

  const { data: workflows = null } = useGetWorkflowsQuery(
    {
      contactId: contact?.id,
    },
    { skip: !contact?.id },
  );
  const { data: templates = null } = useGetWorkflowTemplatesQuery();

  // Create a map of templates by type
  const templatesKeyed = templates
    ? templates.reduce((acc, template) => {
        acc[template.type] = template;
        return acc;
      }, {})
    : {};

  const gatewayShowTemplateIds = (templates || [])
    .filter((template) => template.template.gatewayShow === true)
    .map((template) => template.type);

  // Filter out templates that are already assigned to the contact and are active
  const filteredTemplates = (templates || []).filter(
    (template) =>
      gatewayShowTemplateIds.includes(template.type) &&
      !workflows?.some((workflow) => workflow.templateId === template.type),
  );

  // Only show types opted-in for Charging Gateway
  const filteredWorkflows = (workflows || []).filter((workflow) =>
    gatewayShowTemplateIds.includes(workflow.templateId),
  );

  const [assignedWorkflow, setAssignedWorkflow] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [session, setSession] = useState(null);

  function handleSelectedWorkflow(template) {
    setSelectedWorkflow(template);
  }

  async function fetchExistingSessionLink(workflowType) {
    const res = await assignWorkflowMethod({
      workflowType: workflowType,
      hsContactId: contact?.id,
      deal: selectedDeal,
    });
    // TODO remove PESKY SWITCH CASE, let onboarding server handle logic and routing
    // TODO when done, all there is to run is the default case contents.
    // TODO also remove the ENV variable REACT_APP_ORDERFORM_URL, as the target should be configurable in the workflowTemplate config

    switch (workflowType) {
      case "schedule_call": {
        setAssignedWorkflow(() => workflowType);
        setSessionUrl(() => `${orderformUrl}/?deal=${selectedDeal}`);
        setSession(null);

        break;
      }

      default: {
        setAssignedWorkflow(workflowType);
        setSession(res.data.session.uuid);
      }
    }
  }

  useEffect(() => {
    if (session) {
      setSessionUrl(() => `${onboardingServerUrl}?sessionId=${session}`);
    }
  }, [session]);

  async function createWorkflow() {
    // const res = await assignWorkflowMethod({
    //   workflowType: selectedWorkflow?.type,
    //   hsContactId: contact?.id,
    // });
    fetchExistingSessionLink(selectedWorkflow?.type);
    // setAssignedWorkflow(selectedWorkflow?.type);
    // setSession(res.data.session.uuid);
    setSelectedWorkflow(null);
  }

  return (
    <>
      <h2 className="mb-3 text-blue-500">Onboarding</h2>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          {filteredWorkflows.length > 0 ? (
            <>
              {filteredWorkflows.map((workflow) => {
                const current = templatesKeyed[workflow.templateId];
                const currentType = current?.type; //salesonboard
                const { finished, label } = parseStatus(
                  workflows.find((w) => w.templateId === currentType)?.status,
                );

                return (
                  <div className="flex items-center" key={workflow.templateId}>
                    <p
                      className={twMerge(
                        "m-1 cursor-pointer rounded border-2 p-1",
                        finished
                          ? "border-green-500/35 bg-green-50 text-green-700 hover:bg-green-100"
                          : "border-blue-500/35 bg-blue-50 text-blue-700 hover:bg-blue-100",
                      )}
                      onClick={() =>
                        fetchExistingSessionLink(workflow.templateId)
                      }
                    >
                      {templatesKeyed[workflow.templateId]?.template?.label ||
                        ""}
                      {": " + label}
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <p>No workflows assigned.</p>
          )}
        </div>
        {sessionUrl && (
          <div className="mt-4 text-xs">
            <h3>
              Prefilled{" "}
              <span className="font-medium">
                {templatesKeyed[assignedWorkflow]?.template?.label}{" "}
              </span>
              link:
            </h3>
            <a
              className="mt-1 block text-blue-500 underline"
              target="_blank"
              rel="noreferrer"
              href={sessionUrl}
            >
              {sessionUrl}
            </a>
          </div>
        )}
        <hr className="my-4" />
        <FieldGroup label="Assign workflow">
          <WorkflowDropdown
            selected={selectedWorkflow}
            onChange={handleSelectedWorkflow}
            products={filteredTemplates}
          />
        </FieldGroup>
        <button
          onClick={createWorkflow}
          disabled={!selectedWorkflow}
          className={`rounded p-2 text-xs text-white ${
            selectedWorkflow ? "bg-blue-500" : "cursor-not-allowed bg-gray-400"
          }`}
        >
          Assign workflow
        </button>
      </div>
    </>
  );
};

export default BoardingPane;
